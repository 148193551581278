import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { Button, Container, Grid, Typography } from 'src/app/components';

import {
  FormErrors,
  // FormArrayElement,
  FormRootContainer,
  FormRootContainerProps,
  FormControl,
  FormLayout,
  // FormDateField,
  // FormNumberField,
  // FormTextField,
} from 'src/app/components/RHF';
import { useStyles } from 'src/app/context';
import { useTheme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';

// import { FormFieldType } from 'src/app/constants';
import { FormControlConfig, BaseLocalization } from 'src/app/types';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

interface FormElementProps {
  // as: ((props: any) => React.FunctionComponentElement<any>) | React.FunctionComponentElement<any>; // TODO: narrow down
  children?: React.ReactNode;
  defaultValue?: string; // TODO: extends element by element
  fullWidth?: boolean;
  // id: string;
  isDisabled?: boolean;
  name: string;
  label: string;
  rules?: {};
  variant?: TextFieldProps['variant'];
}
interface FormTypeTest extends Record<string, unknown> {
  id: string;
  countryData: {};
  localizations: BaseLocalization[];
  // imgUrls: string[];
}

const defaultValues: FormTypeTest = {
  id: 'defaultId',
  countryData: {
    inhabitantCount: 4711,
  },
  localizations: [],
  // imgUrls: [];
};

// const localizationsFormArrayConfig: FormElementProps = {
//   // localizationId: ''
//   // // id: 'foo-field-id',
//   // name: 'fooNameArray',
//   // label: 'LabelArrayValue',
// };

export interface FieldErrorsProps {
  name: string;
}

const FieldErrors = ({ name }: FieldErrorsProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  const hasError = !!errors?.[name];
  return hasError ? (
    <Grid container>
      <Grid item xs={12}>
        FieldErrors
      </Grid>
    </Grid>
  ) : null;
};

const FieldAdditionalContainer = ({ children }: { children?: React.ReactNode }) => {
  return children ? (
    <Grid container>
      <Grid item xs={12}>
        FieldAdditionalContainer
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  ) : null;
};

export default () => {
  // const theme = useTheme();
  if (
    typeof window === 'undefined' ||
    (window.location.hostname !== 'localhost' &&
      window.location.hostname !== 'travlr-dev.netlify.app') // TODO: .env
  ) {
    return null;
  }

  const styles = useStyles();

  const formProps: FormRootContainerProps<FormTypeTest> = {
    defaultValues,
    isDebug: false,
    onSubmit: (data: FormTypeTest) => console.log('Hallo submit'),
  };

  const isDebug = true;

  const isValid = true; // TODO:

  return null;

  // return (
  //   <Container>
  //     <Grid container spacing={4}>
  //       <Grid item xs={12}>
  //         <Typography align={'center'} variant={'h4'}>
  //           Form tests
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12} style={{ padding: '64px' }}>
  //         <FormRootContainer<FormTypeTest> {...formProps} isDebug={isDebug}>
  //           <FormLayout
  //             header={
  //               <Grid item xs={12}>
  //                 <Typography align={'center'} variant={'h3'} renderAs={'h2'}>
  //                   FormPageTitle
  //                 </Typography>
  //               </Grid>
  //             }
  //             formControl={<FormControl isValid={isValid} /* hasReset */ />}
  //             renderErrors={<FormErrors i18nBasePath={i18nBasePath} />}
  //           >
  //             <FormTextField label={'String field'} name={'id'}>
  //               <FieldErrors name={'id'} />
  //               <FieldAdditionalContainer />
  //             </FormTextField>
  //             <FormNumberField
  //               label={'Number field'}
  //               name={'countryData.inhabitantCount'}
  //             >
  //               <FieldErrors name={'countryData.inhabitantCount'} />
  //               <FieldAdditionalContainer />
  //             </FormNumberField>
  //             <FormArrayElement
  //               initialValues={{ localeId: '', locales: { name: '', description: '' } }}
  //               renderElements={(renderProps: { namePrefix: string }) => {
  //                 const { namePrefix } = renderProps;
  //                 return (
  //                   <>
  //                     <Grid item xs={12}>
  //                       {renderProps?.namePrefix || ''}
  //                     </Grid>
  //                     <FormTextField label={'LocaleId'} name={`${namePrefix}.localeId`}>
  //                       <FieldErrors name={`${namePrefix}.localeId`} />
  //                       <FieldAdditionalContainer />
  //                     </FormTextField>
  //                     <FormTextField label={'Name'} name={`${namePrefix}.locales.name`}>
  //                       <FieldErrors name={`${namePrefix}.locales.name`} />
  //                       <FieldAdditionalContainer />
  //                     </FormTextField>
  //                     <FormTextField
  //                       label={'Description'}
  //                       name={`${namePrefix}.locales.description`}
  //                     >
  //                       <FieldErrors name={`${namePrefix}.locales.description`} />
  //                       <FieldAdditionalContainer />
  //                     </FormTextField>
  //                   </>
  //                 );
  //               }}
  //               name={'localizations'}
  //             >
  //               <FieldErrors name={'localizations'} />
  //               <FieldAdditionalContainer />
  //             </FormArrayElement>

  //             {/* <FormImageUploadElement name={'imageUrls'} {...testArrayFormConfig}>
  //               <FieldErrors />
  //               <FieldAdditionalContainer />
  //             </FormImageUploadElement> */}
  //             {/* <FormDateField label={'Date field'} name={'date'}>
  //               <>Additional Date Stuff</>
  //             </FormDateField> */}
  //           </FormLayout>
  //         </FormRootContainer>
  //       </Grid>
  //     </Grid>
  //   </Container>
  // );
};

// OLD EXAMPLE

// // TODO: i18n
// const configs: { configs: { fields: FormControlConfig[] }[] }[] = [
//   {
//     configs: [
//       {
//         fields: [
//           {
//             id: 'email',
//             defaultValue: '',
//             rules: { required: true },
//             type: FormFieldType.string,
//             autocomplete: true,
//           },
//           {
//             id: 'password',
//             defaultValue: '',
//             rules: { required: true },
//             type: FormFieldType.string,
//             inputType: 'password',
//             autocomplete: true,
//           },
//         ],
//       },
//     ],
//   },
// ];

// interface LoginFormValues extends FieldValues {
//   email: string;
//   password: string;
// }

// const defaultValues: LoginFormValues = {
//   email: '',
//   password: '',
// };

// const testDefaultValues: LoginFormValues = {
//   email: 'willkuerlich0@gmail.com',
//   password: '123456',
// };

// export interface LoginFormProps extends BaseComponentProps {
//   children?: React.ReactNode;
// }

// /**
//  * LoginForm / WAPLoginForm - MUI
//  * @param { LoginFormProps } props
//  * @version 0.0.0
//  * @description
//  * @todo
//  * @example
//  */
// const LoginForm = (props: LoginFormProps) => {
//   const { children } = props;
//   const localizationPath = 'shop:screen-login';
//   const { closeDialog } = useAppContext();
//   const formMethods = useForm<LoginFormValues, {}>({
//     defaultValues,
//     mode: 'onSubmit',
//     reValidateMode: 'onSubmit',
//     criteriaMode: 'all',
//     // shouldFocusError: true,
//     // shouldUnregister: true,
//   });

//   const { handleSubmit, watch, errors, control, register, setValue } = formMethods;

//   // const watchedValues = watch();

//   function signInWithEmailPassword(signupFormData: { email: string; password: string }) {
//     if (window && typeof window !== 'undefined') {
//       firebase
//         .auth()
//         .signInWithEmailAndPassword(signupFormData.email, signupFormData.password)
//         .then((user) => {
//           console.log('signed in, user: ', user);
//           // Signed in
//           // ...
//         })
//         .catch((error) => {
//           // const errorCode = error.code;
//           // const errorMessage = error.message;
//           console.log('got error: ', error);
//           // ..
//         });
//     }
//   }

//   const onSubmit = (formData: { email: string; password: string }) => {
//     signInWithEmailPassword(formData);
//     // login with new account => check if is already logged in?
//     closeDialog();
//   };

//   return (
//     <FormProvider {...formMethods}>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <ContextForm
//           // hasSubmittedForm={hasSubmittedErrorForm}
//           {...configs[0]}
//           isLocalized
//           i18nPath={`${localizationPath}`}
//         />
//         <Button color={'primary'} fullWidth variant={'contained'} type={'submit'}>
//           <Typography >
//             <Localized dictKey={`account:screen.screen-account-${'login'}.nav label`} />
//           </Typography>
//         </Button>
//         <DevButton onClick={() => signInWithEmailPassword(testDefaultValues)}>TestLogin</DevButton>
//       </form>
//     </FormProvider>
//   );
// };
